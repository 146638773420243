import React, { useEffect } from "react"
import Meta from "../utilities/seo"

const Ele = () => {
  useEffect(() => {
    window.location.href = "https://join.myxeno.com/?country=KE&group=FAMILY"
  }, [])
  return (
    <>
      <Meta title="Create a Family Account" />
    </>
  )
}

export default Ele
